import { FC, useMemo, useRef } from 'react'
import _ from 'lodash'
import { Flex, Heading, Box, Button, VStack, HStack } from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { useSelector } from 'model/hooks'
import { DictT, TourSlotT, ItemT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PanelCartItem from 'shared/components/PanelCartItem'
import { faGrid2Plus, faPlus } from '@fortawesome/pro-regular-svg-icons'
import { MpSdk } from 'shared/bundle/sdk'
import ResetDesignAlert, {
  IResetDesignAlert
} from 'shared/components/ResetDesignAlert'

type Props = {
  sdk: MpSdk
  tourSlots: DictT<TourSlotT>
  designName?: string
  onAddFurnitureClick: () => void
  onAddSetClick?: () => void
  openProductCard: (item: ItemT) => void
  addToRoom: (itemId: string) => void
  removeItem: (itemId: string) => void
  selectItem: (itemId: string) => void
  onResetDesign?: () => void
}

const UserItemsPanel: FC<Props> = ({
  sdk,
  tourSlots,
  designName,
  onAddFurnitureClick,
  onAddSetClick,
  openProductCard,
  addToRoom,
  removeItem,
  onResetDesign,
  selectItem
}) => {
  const items = useSelector(state => state.items)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const alertRef = useRef<IResetDesignAlert>(null)
  const sortedItems = useMemo(() => {
    const byItemId: DictT<TourSlotT[]> = _.groupBy(tourSlots, 'itemId')
    const itemsAr = _.map(byItemId, items => _.sortBy(items, 'createdAt'))
    return _.sortBy(itemsAr, ar => ar[0].createdAt)
  }, [tourSlots])

  const renderCartItems = () => {
    if (items) {
      const res: any = []
      _.forEach(sortedItems, ar => {
        _.forEach(ar, (tourSlot, i) => {
          const item = _.get(items, tourSlot.itemId)
          const mattertagId = _.get(mattertags, tourSlot.id)
          if (item) {
            res.push(
              <PanelCartItem
                sdk={sdk}
                key={tourSlot.id}
                item={item}
                index={i}
                quantity={_.size(ar)}
                openProductCard={openProductCard}
                addToRoom={addToRoom}
                onRemove={() => removeItem(tourSlot.id)}
                onSelect={() => selectItem(tourSlot.id)}
                mattertagId={mattertagId}
                supplierName={
                  item.supplierId && _.get(suppliers, [item.supplierId, 'name'])
                }
                canEdit
                pricesVisible
              />
            )
          } else {
            console.warn('item of tour slot not found, ts:', tourSlot)
          }
        })
      })
      return res
    }
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {designName || 'Do It Yourself'}
                </Heading>
              </VStack>
            </HStack>
            {onResetDesign && (
              <Button
                size='xs'
                variant={'ghost'}
                color='blue.500'
                onClick={() => alertRef.current?.open()}
              >
                Reset design
              </Button>
            )}
          </Flex>
        </Box>
        <VStack
          direction='column'
          align={'center'}
          justify='flex-start'
          flex={1}
          h='full'
          overflowX='hidden'
          overflowY={'auto'}
          sx={{ '.divider': { margin: 0 } }}
          divider={
            <Box
              className='divider'
              borderColor={'gray.300'}
              borderBottomWidth={1}
              w='full'
            />
          }
          // scrollSnapType={'y proximity'}
        >
          {renderCartItems()}
        </VStack>
        <HStack bg='white' w='full' p={4} spacing={4}>
          <Button
            leftIcon={<FontAwesomeIcon icon={faPlus} />}
            w='full'
            // variant={'primary'}
            size='lg'
            colorScheme={'blue'}
            onClick={() => onAddFurnitureClick()}
          >
            Add item
          </Button>
          <Button
            leftIcon={<FontAwesomeIcon icon={faGrid2Plus} />}
            w='full'
            variant={'outline'}
            size='lg'
            colorScheme={'blue'}
            onClick={() => onAddSetClick && onAddSetClick()}
          >
            Add set
          </Button>
        </HStack>
        <ResetDesignAlert
          ref={alertRef}
          onApply={() => (onResetDesign ? onResetDesign() : null)}
        />
      </Flex>
    </RightPanelWrapper>
  )
}

export default UserItemsPanel
